import Headroom from "headroom.js";

export default function() {
    var _header = document.querySelector("header");
    var _options = {
        offset: _header.clientHeight
    };

    var _hr = new Headroom(_header, _options);
    _hr.init();
}
