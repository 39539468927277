export default function() {
    var _address = "Brusselsesteenweg 119, 9090 Melle";
    var _icon =
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40.925' height='49.428' viewBox='0 0 40.925 49.428'%3E%3Cg transform='translate(-323 -3978.323)'%3E%3Cg transform='translate(562.747 3711.007)'%3E%3Cpath d='M-239.747,287.778a20.462,20.462,0,0,1,20.462-20.462,20.462,20.462,0,0,1,20.462,20.462c0,18.218-20.462,28.966-20.462,28.966S-239.747,306-239.747,287.778Z' transform='translate(0)' fill='%236e6e6e'/%3E%3Cg transform='translate(-230.212 280.184)'%3E%3Cpath d='M-220.8,333.073h5.15l4.17-5.15h-5.15Z' transform='translate(220.804 -317.622)' fill='%23ebebeb'/%3E%3Cpath d='M-200.176,301.887l-1.595,1.97-5.765-7.12h-10.3l8.34,10.3h10.3l4.17-5.15Z' transform='translate(218.817 -296.737)' fill='%23ebebeb'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
    var _styles = [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ];

    var _mapOptions = {
        zoom: 15,
        styles: _styles,
        disableDefaultUI: true
    };

    window.initMap = function() {
        var _element = document.getElementById("map");
        var _map = new google.maps.Map(_element, _mapOptions);
        var _geocoder = new google.maps.Geocoder();

        geocodeAddress(_geocoder, _map);
    };

    window.geocodeAddress = function(geocoder, resultsMap) {
        geocoder.geocode({ address: _address }, function(results, status) {
            if (status === "OK") {
                resultsMap.setCenter(results[0].geometry.location);

                var _marker = new google.maps.Marker({
                    map: resultsMap,
                    position: results[0].geometry.location,
                    icon: {
                        url: _icon,
                        scaledSize: new google.maps.Size(41, 49)
                    }
                });
            } else {
                console.error("Geocode was not successful for the following reason: " + status);
            }
        });
    };
}
