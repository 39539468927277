import objectFit from './object-fit';
import maps from './maps';
import carousel from './carousel';
import navigation from './navigation';
import aos from './aos';
import forms from './forms';
import headroom from './headroom';
import lightboxGallery from './lightbox-gallery';

maps();
navigation();
carousel();
aos(); //animate on scroll
objectFit();
forms();
headroom();
lightboxGallery();