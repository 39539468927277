import Swiper from "swiper";
// import { isUndefined } from "util";

export default function() {
    function multiSwiper() {
        var _mediaGroup = new Swiper(".Hero-media-group.swiper-container", {
            autoHeight: true,
            loop: true
        });

        var _bodyGroup = new Swiper(".Hero-body-group.swiper-container", {
            autoHeight: true,
            effect: "fade",
            speed: 1000,
            loop: true,
            pagination: {
                el: ".Hero-pagination",
                type: "bullets",
                clickable: true
            }
        });

        if (_mediaGroup.controller !== undefined && _bodyGroup.controller !== undefined) {
            _mediaGroup.controller.control = _bodyGroup;
            _bodyGroup.controller.control = _mediaGroup;
        }
    }

    function projectsSwiper() {
        var _projectCarousel = new Swiper(".ProjectsCarousel .swiper-container", {
            autoHeight: true,
            loop: true,
            // centeredSlides: true,
            breakpointsInverse: true,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            breakpoints: {
                900: {
                    slidesPerView: 2
                },
                1200: {
                    slidesPerView: 3
                }
            }
        });
    }

    multiSwiper();
    projectsSwiper();
}
