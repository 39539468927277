// export default function() {
//     window.renderFormSuccess = function(frm) {
//         var parent = frm.parentNode;
//         var msg = parent.querySelector(".success-message");
//         msg.style.display = "block";
//         parent.removeChild(frm);
//     };
// }


export default function() {

    var allForms = Array.from(document.querySelectorAll(".Form"));
    
    allForms.forEach(function(frm, idx) {

        console.log(frm);
        
        frm.addEventListener('freeform-ready', function(event) {
            const freeform = event.target.freeform;
            console.log("freeform 2", freeform);
            
            freeform.addOnSuccessfulAjaxSubmit(function(event, form, response) {
                var parent = form.parentNode;
                var msg = parent.querySelector(".success-message");
                msg.style.display = "block";
                parent.removeChild(frm);
            })
        });

    });

    
    
}