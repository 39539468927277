export default function() {
    var _buttons = document.querySelectorAll(".hamburger");
    var _html = document.querySelector("html");

    [].forEach.call(_buttons, _button => {
        _button.addEventListener("click", function() {
            _html.classList.toggle("open-menu");
            [].forEach.call(_buttons, _button => { _button.classList.toggle("is-active"); });
        });
    });


}
